import me from './assets/me.JPG';
import artist from './assets/artist.png';
import globe from './assets/globe.png';
import camera from './assets/camera.png';
import handshake from './assets/handshake.png';
import truck from './assets/truck.png';
import crown from './assets/crown.png';
import emoji from './assets/emoji.png';
import ster from './assets/ster.png';
import luuk from './assets/luuk.png';
import emin from './assets/emin.png';
import emma from './assets/emma.png';
import { RxHamburgerMenu } from 'react-icons/rx';
import { FaTiktok } from 'react-icons/fa';
import { AiOutlineClose, AiOutlineInstagram, AiOutlineFacebook, AiOutlineLinkedin, AiOutlineTwitter } from 'react-icons/ai';
import { useState } from 'react';

function App() {
  const [activeMenu, setActiveMenu] = useState(false)

  const handleMenu = () => {
    setActiveMenu((prevState) => !prevState)
  }

  return (
    <div className="App">
      <nav>
        {activeMenu &&
          <ul onClick={handleMenu}>
            <a href="#intro"><li>Over Mezelf</li></a>
            <a href="#vaardigheden"><li>Mijn Vaardigheden</li></a>
            <a href="#succes"><li>Succesvolle Projecten</li></a>
            <a href="#ervaringen"><li>Ervaringen</li></a>
          </ul>
        }
        <div className="hamburgermenu" onClick={handleMenu}>
          {activeMenu ? <AiOutlineClose /> : <RxHamburgerMenu />}
        </div>
      </nav>
      <header id="intro">
        <div className="header">
          <img src={me} alt="me" width="100%" height="500" />
        </div>
      </header>
      <main>
        <section className="intro">
          <img src={emoji} alt="memoji" width="64" />
          <h1>Alex Eenjes</h1>
          <p>Mijn naam is Alex Eenjes, 22 jaar oud, wonende te Nieuwegein en sinds mijn twaalfde ben ik al bezig met ondernemen. <br /><br />Waar ik begon bij logo's en video's editen ben ik door de jaren heen uitgegroeid tot een specialist en consultant omtrent product ontwikkeling, marketing, content creatie, sales, service/levering en klantenservice.</p>
          <a href="https://calendly.com/alexeenjes/strategiegesprek" className="strategiecall">Gratis Strategiegesprek Inplannen</a>
        </section>
        <div id="vaardigheden" className="banner skills">
          <h2>Mijn Vaardigheden</h2>
        </div>
        <section className="skills">
          <div className="items">
            <div className="item">
              <img src={artist} alt="product ontwikkeling" />
              <h3>Product Ontwikkeling</h3>
              <p>Het ontwikkelen van nieuwe producten en het verbeteren van bestaande producten. Mijn expertise omvat het hele proces, van conceptontwikkeling tot productlancering en onderhoud.</p>
            </div>
            <div className="item">
              <img src={globe} alt="marketing" />
              <h3>Marketing</h3>
              <p>Het ontwikkelen van een online marketingstrategie die past bij uw bedrijf en doelen. Ik ben gespecialiseerd in websites bouwen, digitale marketing, social media, e-mailmarketing en SEO.</p>
            </div>
            <div className="item">
              <img src={camera} alt="content creatie" />
              <h3>Content Creatie</h3>
              <p>Het creëren van online content die past bij uw bedrijf en doelgroep. Dit omvat het creëren van blogs, whitepapers, video's, foto's en meer.</p>
            </div>
            <div className="item">
              <img src={handshake} alt="sales" />
              <h3>Sales</h3>
              <p>Het ontwikkelen van online verkoopstrategieën en het opzetten van verkooppijplijnen voor meer omzet.</p>
            </div>
            <div className="item">
              <img src={truck} alt="service/levering" />
              <h3>Service/Levering</h3>
              <p>Het verbeteren van uw online dienstverlening om zo uw klanten tevreden te stellen en hun loyaliteit te vergroten.</p>
            </div>
            <div className="item">
              <img src={crown} alt="klantenservice" />
              <h3>Klantenservice</h3>
              <p>Het opzetten van een online klantenservice die efficiënt en effectief is en uw klanten tevreden stelt.</p>
            </div>
            <a href="https://calendly.com/alexeenjes/strategiegesprek" className="strategiecall">Gratis Strategiegesprek Inplannen</a>
          </div>
        </section>
        <div id="succes" className="banner succes">
          <h2>Succesvolle Projecten</h2>
        </div>
        <section className="succes">
          <div className="projects">
            <div className="project instagram">
              <h3>3.000.000 Instagram volgers</h3>
              <p>Vanaf mijn 12e tot 18e heb ik een Instagram
                netwerk van 3.000.000 volgers opgebouwd.</p>
            </div>
            <div className="project modsi">
              <h3>MODSi</h3>
              <p>Met een campagne de
                artiest MODSi gepromoot naar 2.500.000 Spotify streams.</p>
            </div>
            <div className="project salesmissie">
              <h3>Sales Missie</h3>
              <p>Voor opdrachtgevers deals binnen gehaald
                en mensen opgeleid tot salespersonen.</p>
            </div>
            <div className="project younghustlers">
              <h3>Young Hustlers</h3>
              <p>Een ondernemerscommunity voor jonge ondernemers met 1 op 1 begeleiding.</p>
            </div>
            <div className="project degrootehond">
              <h3>De Groote Hond</h3>
              <p>Een inspiratie-/informatie avond gegeven voor
                de jeugd uit Nieuwegein.</p>
            </div>
            <p className="more">en nog veel meer...</p>
            <a href="https://calendly.com/alexeenjes/strategiegesprek" className="strategiecall">Gratis Strategiegesprek Inplannen</a>
          </div>
        </section>
        <div id="ervaringen" className="banner ervaringen">
          <h2>Ervaringen</h2>
        </div>
        <section>
          <div className="ervaringen">
            <div className="ervaring">
              <img src={luuk} alt="luuk" />
              <h3>Luuk</h3>
              <p>Blij met onze samenwerking. Dankzij de vaardigheden van Alex heeft mijn bedrijf op gebied van marketing en sales flinke stappen vooruit kunnen maken.</p>
              <div className="stars">
                <div className="star">
                  <img src={ster} alt="ster" />
                </div>
                <div className="star">
                  <img src={ster} alt="ster" />
                </div>
                <div className="star">
                  <img src={ster} alt="ster" />
                </div>
                <div className="star">
                  <img src={ster} alt="ster" />
                </div>
                <div className="star">
                  <img src={ster} alt="ster" />
                </div>
              </div>
            </div>

            <div className="ervaring">
              <img src={emin} alt="emin" />
              <h3>Emin</h3>
              <p>Ik heb Alex leren kennen via een netwerk evenement, waar we dan ook aan de praat raakten. Een week later hadden we een samenwerking. Lekker flexibel en blij met het resultaat!</p>
              <div className="stars">
                <div className="star">
                  <img src={ster} alt="ster" />
                </div>
                <div className="star">
                  <img src={ster} alt="ster" />
                </div>
                <div className="star">
                  <img src={ster} alt="ster" />
                </div>
                <div className="star">
                  <img src={ster} alt="ster" />
                </div>
                <div className="star">
                  <img src={ster} alt="ster" />
                </div>
              </div>
            </div>

            <div className="ervaring">
              <img src={emma} alt="emma" className="emma" />
              <h3>Emma</h3>
              <p>Ik liep al jaren rond met een concept waarvan ik niet wist hoe ik dit tot uiting moest brengen. Alex heeft mij hier van A tot Z bij geholpen. Van conceptstukken tot de daadwerkelijke uitvoering met veel nieuwe klanten!</p>
              <div className="stars">
                <div className="star">
                  <img src={ster} alt="ster" />
                </div>
                <div className="star">
                  <img src={ster} alt="ster" />
                </div>
                <div className="star">
                  <img src={ster} alt="ster" />
                </div>
                <div className="star">
                  <img src={ster} alt="ster" />
                </div>
              </div>
            </div>
          </div>
          <a href="https://calendly.com/alexeenjes/strategiegesprek" className="strategiecall">Gratis Strategiegesprek Inplannen</a>
        </section>
      </main>
      <footer>
        <p>© Alex Eenjes {new Date().getFullYear()} - Alle rechten voorbehouden.</p>
        <div className="socials">
          <a href="https://www.instagram.com/eenjesta/"><AiOutlineInstagram /></a>
          <a href="https://www.facebook.com/alex.eenjes/"><AiOutlineFacebook /></a>
          <a href="https://www.linkedin.com/in/eenjesta/"><AiOutlineLinkedin /></a>
          <a href="https://twitter.com/eenjesta"><AiOutlineTwitter /></a>
          <a href="https://www.tiktok.com/@eenjesta"><FaTiktok className="tiktok" /></a>
        </div>
      </footer>
    </div>
  );
}

export default App;
